import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import Divider from "../../components/divider"
import GebaerdenVideoGrid from "../../content/shared/gebaerden-video-grid"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import VideoPlayer from "../../components/video-player"

const GebaerdenSpracheMuseumsgarten = () => {
  const data = useStaticQuery(graphql`
    {
      poster: file(
        relativePath: { eq: "videos/poster-gebaerdensprache-garten.jpg" }
      ) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/leichte-sprache.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Museumsgarten"
        description="Hier finden Sie Informationen über den Museumsgarten des Hölderlinturms in Deutscher Gebärdensprache."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Gebärdensprache",
              link: "/gebaerdensprache",
            },
            {
              title: "Museumsgarten",
              link: "/gebaerdensprache/garten",
            },
          ]}
        />
        <PageTitle>Museumsgarten</PageTitle>

        <VideoPlayer src="gebaerdensprache/garten" poster={data.poster} />

        <Divider size={4} />
        <GebaerdenVideoGrid active="garten" />
      </Stack>
    </Layout>
  )
}

export default GebaerdenSpracheMuseumsgarten
